import { formatCurrency } from '@angular/common';
import { DEFAULT_CURRENCY_CODE, Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'semmieCurrency',
})
export class SemmieCurrencyPipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    @Inject(DEFAULT_CURRENCY_CODE) private defaultCurrencyCode: string,
  ) {}

  transform(
    value: number | string | null = 0,
    showPositiveNotation = false,
    digitsInfo?: string,
    currencySymbol?: string,
    currencyCode?: string,
  ) {
    if (typeof value === 'string') {
      value = parseFloat(value);
    }

    if (value === null || isNaN(value)) {
      value = 0;
    }

    let prefix = '';
    const isNegative = value < 0;
    const positiveSymbol = showPositiveNotation ? '+' : '';

    value = Math.abs(value);

    if (value !== 0) {
      prefix = isNegative ? '-' : positiveSymbol;
    }

    const formattedCurrecy = formatCurrency(
      value,
      this.locale,
      currencySymbol || '€',
      currencyCode || this.defaultCurrencyCode,
      digitsInfo || '1.2-2',
    );

    return `${prefix}${formattedCurrecy}`;
  }
}
