<semmie-card>
  <div class="flex items-stretch">
    <div class="grow">
      <semmie-label *ngIf="title" class="reward-card-title" type="heading" size="h3" [text]="title"></semmie-label>
      <semmie-label
        class="reward-card-subtitle"
        size="base_3"
        [text]="reward.account.title ?? ('core.common.account.title_unknown' | translate)"
      ></semmie-label>
    </div>
    <div class="self-center">
      <ng-container [ngSwitch]="reward.rewardKind">
        <semmie-label
          *ngSwitchCase="RewardKind.CASH"
          class="reward-card-reward"
          size="base_3"
          [text]="reward.amount | semmieCurrency: true : '1.0-2'"
        ></semmie-label>
        <semmie-label
          *ngSwitchCase="RewardKind.MONTHS_NO_FEE"
          class="reward-card-reward"
          size="base_3"
          [text]="
            '+' +
            ('boosters.rewards.months_no_fee'
              | translate
                : {
                    amount:
                      (reward.amount | number: '1.0') +
                      ' ' +
                      ((reward.amount | number: '1.0') === '1'
                        ? ('boosters.terms.month' | translate)
                        : ('boosters.terms.months' | translate)),
                  })
          "
        ></semmie-label>
      </ng-container>
    </div>
  </div>
</semmie-card>
