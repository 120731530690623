import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationEnd, NavigationExtras, Router, UrlCreationOptions, UrlTree } from '@angular/router';

import { NavController } from '@ionic/angular';

import { filter } from 'rxjs/operators';

import { BaseNavigationService } from '@semmie/services/navigation/__abstract/base-navigation.service';
import { MainRouteNames } from '@onyxx/model/main';
import { NavigationOptions } from '@ionic/angular/common/providers/nav-controller';

@Injectable({
  providedIn: 'root',
})
export class NavigationService extends BaseNavigationService {
  constructor(
    private router: Router,
    private navCtrl: NavController,
    private location: Location,
  ) {
    super();
    this.router.events.pipe(filter((r): r is NavigationEnd => r instanceof NavigationEnd)).subscribe((r) => this.addRouteUrl(r.url));
  }

  navigate(commands: Array<any>, extendedOpts?: NavigationOptions, params?: { backwards: boolean }): Promise<any> {
    const opts: Partial<NavigationExtras> = { ...(extendedOpts ?? {}) };

    const nextCommand = this.getCommand(commands);

    if (opts?.replaceUrl) {
      this.allRoutes = [];
    }

    if (params?.backwards) {
      return this.navCtrl.navigateBack(nextCommand, opts);
    }

    return this.navCtrl.navigateForward(nextCommand, opts);
  }

  navigateByUrl(url: string | UrlTree | any[], extraParams?: NavigationOptions) {
    return this.navCtrl.navigateRoot(url, extraParams);
  }

  async back() {
    if (this.previousRoute) {
      this.navCtrl.back();
      this.allRoutes.pop();
    } else {
      const prevPath = this.location.path().split('/').slice(0, -1);
      await this.navigate(prevPath, { replaceUrl: true }, { backwards: true });
    }
  }

  toDashboard(backwards?: boolean, clearHistory?: boolean): void {
    if (clearHistory) {
      this.navCtrl.navigateRoot(['/', MainRouteNames.Accounts], { replaceUrl: true });
      return;
    }

    if (backwards) {
      this.navCtrl.navigateBack(['/', MainRouteNames.Accounts], { replaceUrl: true });
    } else {
      this.navCtrl.navigateForward(['/', MainRouteNames.Accounts], { replaceUrl: true });
    }
  }

  toAccount(id: string, backwards?: boolean): void {
    if (backwards) {
      this.navCtrl.navigateBack([MainRouteNames.Accounts, id]);
    } else {
      this.navCtrl.navigateForward([MainRouteNames.Accounts, id]);
    }
  }

  createUrlTree(commands: any[], navigationExtras?: UrlCreationOptions): UrlTree {
    return this.router.createUrlTree(commands, navigationExtras);
  }
}
