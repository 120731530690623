<ng-container *ngIf="{ goal: goal$ | async, suggestions: suggestions$ | async } as ctx">
  <ng-container *ngIf="isLoaded(); else loadingTemplate">
    <semmie-card [padding]="false">
      <ng-container *ngIf="ctx.goal && showRetry === false; else retryMessage">
        <ng-container *ngIf="ctx.goal?.state !== GoalState.INVALID && ctx.goal?.graph?.realistic; else invalidGoal">
          <div
            class="account-goal-graph-container"
            [class.containsChart]="ctx.goal && showChart"
            *ngIf="ctx.goal?.suggestions ? chartWhenOfftrack : true"
          >
            <div class="goal-header flex">
              <div class="grow">
                <div class="mb-1 flex flex-row">
                  <semmie-label [text]="expectedYear$ | async" size="h5" weight="medium"></semmie-label>
                  <onyxx-info-modal-icon *ngIf="mode === 'form'" (click)="openCalculateModal()"></onyxx-info-modal-icon>
                </div>
                <semmie-label
                  [text]="
                    (lowestResult$ | async | semmieCurrency: false : '1.0-2') +
                    ' - ' +
                    (highestResult$ | async | semmieCurrency: false : '1.0-2')
                  "
                  size="h2"
                  weight="medium"
                ></semmie-label>
              </div>
              <div *ngIf="showStatus && mode === 'default'" class="state flex items-center self-start" [ngClass]="ctx.goal.state">
                <ng-container [ngSwitch]="ctx.goal.state">
                  <ng-container *ngSwitchCase="GoalState.RECALCULATING">
                    <div class="h-10 w-10">
                      <semmie-loading-ripple position="inline"></semmie-loading-ripple>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <span class="icon">
                      <semmie-icon size="xxxxs" [icon]="getIconForState(ctx.goal.state)"></semmie-icon>
                    </span>
                    @switch (ctx.goal.state) {
                      @case (GoalState.ONTRACK) {
                        <semmie-label
                          class="inline"
                          type="heading"
                          weight="medium"
                          size="h5"
                          i18n-text="@@account-goal.state.ontrack"
                          text="On track"
                        ></semmie-label>
                      }
                      @case (GoalState.OFFTRACK) {
                        <semmie-label
                          class="inline"
                          type="heading"
                          weight="medium"
                          size="h5"
                          i18n-text="@@account-goal.state.offtrack"
                          text="Off track"
                        ></semmie-label>
                      }
                      @case (GoalState.LEARNING) {
                        <semmie-label
                          class="inline"
                          type="heading"
                          weight="medium"
                          size="h5"
                          i18n-text="@@account-goal.state.learning"
                          text="Learning"
                        ></semmie-label>
                      }
                      @case (GoalState.ONTRACK) {
                        <semmie-label
                          class="inline"
                          type="heading"
                          weight="medium"
                          size="h5"
                          i18n-text="@@account-goal.state.recalculating"
                          text="Recalculating"
                        ></semmie-label>
                      }
                    }
                  </ng-container>
                </ng-container>
              </div>
            </div>
            <semmie-goal-chart
              *ngIf="showChart"
              [account]="account"
              [goal]="ctx.goal"
              [datesPension]="datesPension"
              (onScrubHeaderData)="setHeaderData($event)"
            ></semmie-goal-chart>
          </div>

          <ng-container *ngIf="!(ctx.goal?.state === GoalState.OFFTRACK && showSuggestions); else offtrackSuggestions">
            <div
              *ngIf="showFooterButton"
              class="account-goal-content"
              [ngClass]="{ 'cursor-pointer': footerButtonPath }"
              (click)="navigateTo(footerButtonPath)"
            >
              <ng-container *ngIf="footerButtonPath; else statusTpl">
                <div class="button-container">
                  <semmie-button [fullWidth]="true" size="sm" theme="transparent">{{
                    'account-goal-settings.goal.title' | translate
                  }}</semmie-button>
                </div>
              </ng-container>
              <ng-template #statusTpl>
                <div class="content">
                  <semmie-label
                    [text]="'account-goal.monitor.' + ctx.goal.state + '.title' | translate"
                    type="heading"
                    size="h3"
                    class="mb-1"
                    weight="medium"
                  ></semmie-label>
                  <semmie-label
                    class="account-goal-content-description"
                    [text]="'account-goal.monitor.' + ctx.goal.state + '.description' | translate"
                  ></semmie-label>
                </div>
                <div class="icon" *ngIf="footerButtonPath">
                  <semmie-icon size="xs" [icon]="Icon.CHEVRONRIGHT"></semmie-icon>
                </div>
              </ng-template>
            </div>
          </ng-container> </ng-container
      ></ng-container>
    </semmie-card>
  </ng-container>

  <ng-template #invalidGoal>
    <semmie-card [title]="'account-goal.title' | translate">
      <div class="flex flex-col p-10 text-center" *ngIf="AccountPermissions.canChangeWealthPlan(account); else noDataMessage">
        <div class="m-auto">
          <semmie-image [src]="'illustrations/small/plan.png' | assetPath" width="68" height="78"></semmie-image>
        </div>
        <semmie-label [text]="'account-goal.invalid.description' | translate" align="center" class="error-message"></semmie-label>
        <semmie-button
          *ngIf="mode === 'default' && AccountPermissions.canChangeWealthPlan(account)"
          size="sm"
          (onClick)="navigateTo(['../plan'])"
          >{{ 'account-goal.invalid.button' | translate }}</semmie-button
        >
      </div>
    </semmie-card>
  </ng-template>

  <ng-template #loadingTemplate>
    <semmie-card [padding]="false">
      <div class="chart-loader">
        <div class="loader">
          <semmie-loading-ripple position="inline"></semmie-loading-ripple>
        </div>
      </div>
    </semmie-card>
  </ng-template>

  <ng-template #offtrackSuggestions>
    <div class="account-goal-suggestions">
      <div>
        <div class="content" *ngIf="mode === 'default'">
          <semmie-label
            class="account-goal-content-title"
            [text]="'account-goal.monitor.' + ctx.goal?.state + '.title' | translate"
            size="h2"
            weight="medium"
          ></semmie-label>
          <semmie-label
            size="base_3"
            class="account-goal-content-description"
            [htmlContent]="
              'account-goal.monitor.' +
                ctx.goal?.state +
                (AccountHelpers.isAnnuity(account) ? '.description_long_annuity' : '.description_long') | translate
            "
          ></semmie-label>
        </div>
        <div class="content" *ngIf="mode === 'form'">
          <semmie-label
            class="account-goal-content-label"
            [text]="'account-goal.monitor.form.subtitle' | translate"
            size="h5"
            weight="medium"
          ></semmie-label>
          <semmie-label
            class="account-goal-content-title"
            [text]="'account-goal.monitor.form.title' | translate"
            size="h2"
            weight="medium"
          ></semmie-label>
          <semmie-label
            size="base_3"
            class="account-goal-content-description"
            [text]="'account-goal.monitor.form.description' | translate"
          ></semmie-label>
        </div>
        <div *ngIf="ctx.suggestions" class="mb-6 mt-4">
          <semmie-radio-button-group [formControl]="selectedSuggestion" [options]="ctx.suggestions"></semmie-radio-button-group>
        </div>
      </div>
      <div>
        <div class="w-full text-center">
          <semmie-button size="sm" (onClick)="selectSuggestion(ctx.goal)">{{ suggestionButtonText() }}</semmie-button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #retryMessage>
    <div *ngIf="showRetry; else noDataMessage" class="flex flex-col p-10 text-center">
      <div class="m-auto">
        <semmie-image [src]="'illustrations/small/plan.png' | assetPath" width="68" height="78"></semmie-image>
      </div>
      <semmie-label [text]="'account-goal.retry.description' | translate" type="text" align="center" class="error-message"></semmie-label>
      <semmie-button size="sm" (onClick)="retryLoading()">{{ 'account-goal.retry.button' | translate }}</semmie-button>
    </div>
  </ng-template>

  <ng-template #noDataMessage>
    <div class="flex flex-col p-16 text-center">
      <div class="m-auto">
        <semmie-image [src]="'illustrations/small/plan.png' | assetPath" width="68" height="78"></semmie-image>
      </div>
      <semmie-label [text]="'account-goal.noData.description' | translate" type="text" align="center" class="error-message"></semmie-label>
    </div>
  </ng-template>

  <ng-template #depositModal>
    <semmie-modal-header (onCloseClick)="closeModal()"></semmie-modal-header>
    <semmie-intro-content
      [title]="'account-goal.depositModal.title' | translate"
      [label]="'account-goal.depositModal.subtitle' | translate"
      [description]="
        'account-goal.depositModal.description'
          | translate
            : {
                amount: getSuggestionValue(
                  { key: 'deposit_monthly', value: depositModalGoal.suggestions?.deposit_monthly },
                  true,
                  depositModalGoal
                ),
              }
      "
      [image]="'illustrations/coins.png' | assetPath"
      [button]="'account-goal.depositModal.button' | translate"
      (onButtonClick)="navigateTo(['../direct-debit'], true)"
    >
      <div footer class="text-center">
        <semmie-label type="heading" size="h3" [text]="'account-goal.depositModal.footer' | translate"></semmie-label>
        <semmie-label class="text-neutral-700" [text]="account.iban | ibanFormatter"></semmie-label>
      </div>
    </semmie-intro-content>
  </ng-template>
</ng-container>
