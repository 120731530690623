import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  computed,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { BaseFormComponent } from '@semmie/components/_abstract';
import { UploadDocument } from '@semmie/models/bi/upload-document/upload-document.model';
import { CardStyle, Icon } from '@semmie/schemas';
import { iUploadComponent, iUploadFormField, UploadLayout, UploadState } from '@semmie/schemas/components/dynamic-form';
import { ToasterStyle } from '@semmie/schemas/components/toast';
import { HttpProgressStatus } from '@semmie/schemas/generics/http/http-progress-status.enum';
import { HttpProgress } from '@semmie/schemas/generics/http/http-progress.interface';
import { Utils } from '@onyxx/utility/general';
import { StoreHelper } from '@semmie/store/store-helper';
import { BehaviorSubject, combineLatest, EMPTY, Observable, Subject, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, take, tap } from 'rxjs/operators';
import { CardModule } from '@semmie/components/containers/card/card.module';
import { FabButtonComponent } from '@semmie/components/presentational/core/fab-button/fab-button.component';
import { IconComponent } from '@semmie/components/presentational/core/icon/icon.component';
import { ImageModule } from '@semmie/components/presentational/core/image/image.module';
import { PercentageModule } from '@semmie/pipes/percentage/percentage-pipe.module';
import { SharedModule } from '@semmie/shared/shared.module';
import { RxIf } from '@rx-angular/template/if';
import { LabelModule } from '@semmie/components/presentational/core/label';
import { ButtonModule } from '@semmie/components/presentational/core/button/button.module';
import { ButtonThemeEnum } from '@semmie/schemas/common/button';
import { ModalSize, iSelectionModalOption } from '@semmie/schemas/components/modal';
import { ModalService, PlatformService, ToastService, UploadService } from '@semmie/services';
import { FilePickerService, FilePickerSources } from '@semmie/services/file-picker/file-picker.service';
import { FileError, MAX_UPLOAD_SIZE_KB } from '@semmie/schemas/bi/file';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  standalone: true,
  imports: [SharedModule, CardModule, FabButtonComponent, IconComponent, ImageModule, PercentageModule, LabelModule, RxIf, ButtonModule],
  selector: 'semmie-upload',
  templateUrl: 'upload.component.html',
  styleUrls: ['./upload.component.scss'],
  animations: [
    trigger('loaderState', [
      state(
        'active',
        style({
          opacity: 1,
        }),
      ),
      state(
        'inactive',
        style({
          opacity: 0,
          transform: 'scale(0.10)',
        }),
      ),
      transition('inactive => active', [animate('250ms')]),
      transition('active => inactive', [animate('250ms')]),
    ]),
    trigger('buttonState', [
      state(
        'active',
        style({
          opacity: 1,
        }),
      ),
      state(
        'inactive',
        style({
          opacity: 0,
        }),
      ),
      transition('inactive => active', [animate('100ms')]),
      transition('active => inactive', [animate('100ms')]),
    ]),
    trigger('deleteButton', [
      state(
        'active',
        style({
          opacity: 1,
          transform: 'translatex(-2rem)',
        }),
      ),
      state(
        'inactive',
        style({
          opacity: 0,
          transform: 'translatex(0)',
        }),
      ),
      transition('inactive => active', [animate('250ms')]),
      transition('active => inactive', [animate('100ms')]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadComponent extends BaseFormComponent implements OnInit, OnDestroy {
  @Input() url: iUploadFormField['url'];
  @Input() method: iUploadFormField['method'];

  /** Optionally set additional parameters as formdata values */
  @Input() params: iUploadFormField['params'];

  /** Set the upload label */
  @Input() label?: iUploadFormField['label'];

  /** Set the title of the upload options modal */
  @Input() optionsModalTitle?: iUploadFormField['label'];

  /**
   * Sets the upload deletion method by defaults its true so that the old behavior is not disturbed,
   * false should be used when deleting documents from tasks, organisation documents, wherever the new method is used.
   * TODO: Remove this after BE properly implements deleting by ID, how REST tells us it should be done
   */
  @Input() useParamsForDeletion: iUploadFormField['useParamsForDeletion'] = true;

  /** Set the upload style */
  @Input() set layout(layout: iUploadFormField['layout']) {
    this.layout$$.next(layout);
  }

  /** Sets the card style of the upload component */
  @Input() cardStyle: CardStyle;
  @Input() useCardLayout = true;
  /** Use title as subTitle and vice versa */
  @Input() invertTitles = false;

  /** Sets the subtitle of the upload component */
  @Input() subtitle: iUploadFormField['subtitle'];

  /** Sets the allowed file types*/
  @Input() fileTypes: iUploadFormField['fileTypes'];

  /** Refresh specified store after deletion */
  @Input() refresh: iUploadFormField['refresh'];

  /** Indicates if the file should be uploaded after the entity is created */
  @Input() uploadAfterCreation: iUploadFormField['uploadAfterCreation'] = false;

  /** Optionally provide the uploaded file to support scenarios where an upload
   * scan be edited
   * **For now, only the Button layout supports this**  */
  @Input() uploadedFile: string | undefined;

  /** Whether the delete action should trigger when upload fails */
  @Input() deleteOnFail = true;

  /** Whether to show an icon indicating if the file has been uploaded */
  @Input() showStatusIcons = true;

  /** Output id of the deleted document when successfully deleted */
  @Output() onDelete: EventEmitter<string> = new EventEmitter();

  /** Output the selected file */
  @Output() onFileSelected: EventEmitter<File> = new EventEmitter();

  /** Output the document returned by the api when successfully uploaded or the error if one occurs */
  @Output() valueChange: EventEmitter<UploadDocument | Error> = new EventEmitter();

  HttpProgressStatus = HttpProgressStatus;
  readonly UploadLayout = UploadLayout;
  readonly UploadState = UploadState;
  readonly ButtonThemeEnum = ButtonThemeEnum;
  readonly Icon = Icon;

  readonly layout$$ = new BehaviorSubject<UploadLayout>(UploadLayout.Document);
  readonly state$$ = new BehaviorSubject<UploadState>(UploadState.empty);
  readonly file$$ = new BehaviorSubject<any>(null);
  readonly imageSrc$ = new BehaviorSubject<string | null>(null);
  readonly fileSelected$$ = new BehaviorSubject<boolean>(false);
  readonly errorMessage$$ = new BehaviorSubject<null | string>(null);

  readonly uploadComponent$: Observable<iUploadComponent> = combineLatest([
    this.layout$$,
    this.state$$,
    this.file$$,
    this.imageSrc$,
    this.fileSelected$$,
    this.errorMessage$$,
  ]).pipe(
    distinctUntilChanged(),
    map(([layout, state, file, imgSrc, fileSelected, error]) => {
      return {
        layout: layout,
        state: state || UploadState.empty,
        title: this.getUploadStateTitle(state),
        subtitle: Utils.isNotNil(file)
          ? (file.name ?? file.filename ?? file.title)
          : $localize`:@@upload.subtitle.select-document:Select a document`,
        imgSrc: imgSrc,
        fileSelected: fileSelected,
        error: Utils.isNotNil(error) ? error : null,
      };
    }),
  );

  readonly buttonLayoutLabel$$ = computed(() => {
    if (this.label) {
      return this.label;
    } else if (this.uploadedFile) {
      return $localize`:@@upload.document-layout.edit:Change`;
    } else {
      return $localize`:@@upload.document-layout.empty:Upload file`;
    }
  });

  private uploadSub$: Subscription;
  private destroy$: Subject<boolean> = new Subject();

  constructor(
    @Optional() @Self() ngControl: NgControl,
    private storeHelper: StoreHelper,
    private toastService: ToastService,
    private uploadService: UploadService,
    private filePickerService: FilePickerService,
    private platformService: PlatformService,
    private modalService: ModalService,
    private cdr: ChangeDetectorRef,
  ) {
    super(ngControl);
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.layout$$.value === UploadLayout.Image && this.value) {
      this.imageSrc$.next(this.value);
    }

    if (this.value) {
      const isIdentification = Utils.isNotNil(this.value.kind) && Utils.isNotNil(this.value.side);
      if (this.value instanceof UploadDocument || isIdentification) {
        this.state$$.next(UploadState.uploaded);
      } else {
        this.state$$.next(UploadState.selected);
      }
      this.file$$.next(this.value);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  async showUploadModal() {
    const options: (iSelectionModalOption & { onClick: () => void; shouldShow: () => boolean })[] = [
      {
        id: FilePickerSources.Library,
        label: $localize`:@@upload.modal.options.library:Library`,
        onClick: () => {
          this.getFile(FilePickerSources.Library);
        },
        shouldShow: () => this.platformService.isApp && (this.fileTypes?.includes('image') ?? true),
      },
      {
        id: FilePickerSources.Camera,
        label: $localize`:@@upload.modal.options.camera:Make a photo`,
        onClick: () => {
          this.getFile(FilePickerSources.Camera);
        },
        shouldShow: () => this.platformService.isApp && (this.fileTypes?.includes('image') ?? true),
      },
      {
        id: FilePickerSources.Browse,
        label: $localize`:@@upload.modal.options.browse:Browse for file`,
        onClick: () => {
          this.getFile(FilePickerSources.Browse);
        },
        shouldShow: () => true,
      },
      {
        id: 'delete',
        label: $localize`:@@upload.modal.options.delete-image:Delete image`,
        onClick: () => {
          this.doDelete('');
        },
        shouldShow: () => Utils.isNotNil(this.uploadedFile),
        color: 'danger',
      },
    ];

    const filteredOptions = options.filter((option) => option.shouldShow());

    if (filteredOptions.length === 1) {
      filteredOptions[0].onClick();
      return;
    }
    const modal = await this.modalService.openSelectionModal(
      { title: this.optionsModalTitle || $localize`:@@upload.modal.options.title:Upload file` },
      ModalSize.Auto,
      filteredOptions,
    );
    const { data } = await modal.onWillDismiss<(typeof options)[number]>();

    data?.onClick();
  }

  async getFile(source: FilePickerSources) {
    let pickedFile: false | File | File[];
    try {
      pickedFile = await this.filePickerService.pickFile(source, { fileTypes: this.fileTypes });
    } catch (error) {
      console.error('File picker failed', error);
      return;
    }

    /** note: At the moment a Upload component can only handle one file at a time */
    const selectedFile = pickedFile instanceof Array ? pickedFile[0] : pickedFile;

    if (!selectedFile) {
      return;
    }

    if (selectedFile.size / 1_000 > MAX_UPLOAD_SIZE_KB) {
      this.handleError(this.uploadService.getFileErrorMessage(FileError.TooLarge));
      return;
    }

    this.setValue(selectedFile);

    this.file$$.next(selectedFile);
    this.fileSelected$$.next(true);
    this.errorMessage$$.next(null);

    if (this.layout$$.value === UploadLayout.Image && selectedFile) {
      this.processImage(selectedFile);
    }

    this.onFileSelected.emit(selectedFile);
  }

  /**
   * Clear the upload field
   */
  clear(): void {
    if (this.uploadSub$) {
      this.valueChange.emit(new Error($localize`:@@upload.error.cleared:Upload cancelled`));
      this.uploadSub$.unsubscribe();
    }

    this.setValue(null);
    this.refreshData();
    this.onDelete.emit(undefined);

    this.file$$.next(null);
    this.errorMessage$$.next(null);
    this.state$$.next(UploadState.empty);
  }

  /**
   * Request a deletion and remove the value
   * @param id
   */
  doDelete(value: File | UploadComponent | any): void {
    this.state$$.next(UploadState.deleting);

    // If instance is `File` we assume it's a local (selected) File
    // If value is `null` we assume failed upload
    if (value instanceof File || Utils.isNil(value)) {
      this.clear();
      return;
    }
    const useParamsForDeletion = this.useParamsForDeletion ?? true;

    const url = useParamsForDeletion ? this.url : this.url + '/' + value.id;

    this.uploadService
      .delete(url, useParamsForDeletion ? this.params : undefined)
      .pipe(take(1))
      .subscribe(
        () => {
          this.clear();
        },
        () => {
          this.toastService.show({
            header: $localize`:@@upload.toast.delete-error.title:Something went wrong`,
            message: $localize`:@@upload.toast.delete-error.message:Deleting the file failed.`,
            style: ToasterStyle.DANGER,
          });
        },
      );
  }

  upload() {
    this.uploadFile();
    return this.valueChange;
  }

  /**
   * Set form component value
   */
  private setValue(value: UploadDocument | File | undefined | null) {
    this.value = value;
  }

  /**
   * Submit the file to the api
   */
  private uploadFile(): void {
    this.uploadSub$ = this.uploadService
      .upload(this.url, this.method, this.params)
      .pipe(
        tap((progress: HttpProgress) => {
          if (progress.progress < 80) {
            this.state$$.next(UploadState.uploading);
          } else if (progress.progress >= 80) {
            this.state$$.next(UploadState.processing);
          }
        }),
        filter((progress) => progress.state === HttpProgressStatus.DONE),
        take(1),
        catchError((err: HttpErrorResponse) => {
          if (this.deleteOnFail) {
            this.clear();
          }

          let errorMessage: string;
          if (err.status === 413) {
            errorMessage = this.uploadService.getFileErrorMessage(FileError.TooLarge);
          } else if (err.status === 422) {
            switch (err.error.state) {
              case HttpProgressStatus.EXPIRED:
                errorMessage = this.uploadService.getFileErrorMessage(FileError.DocumentExpired);
                break;
              case HttpProgressStatus.INVALID:
                errorMessage = this.uploadService.getFileErrorMessage(err.error.message as FileError);
                break;
              default:
                errorMessage = this.uploadService.getFileErrorMessage(
                  err.error.details?.file?.[0].error || (err.error.details?.image?.[0].error as FileError),
                );
                break;
            }
          } else {
            errorMessage = err.statusText;
          }
          this.handleError(errorMessage);
          if (!this.deleteOnFail) {
            this.state$$.next(UploadState.error);
          }
          return EMPTY;
        }),
      )
      .subscribe(({ state, response }) => {
        if (state === HttpProgressStatus.DONE) {
          const document = new UploadDocument(response.document);
          this.setValue(document);
          this.valueChange.emit(this.value);
          this.state$$.next(UploadState.uploaded);
        }
      });
  }

  private handleError(errorMessage: string) {
    this.errorMessage$$.next(errorMessage);

    if (this.deleteOnFail) {
      this.toastService.show({
        header: $localize`:@@upload.toast.error.title:Something went wrong`,
        message: errorMessage,
        style: ToasterStyle.DANGER,
      });
    }

    this.valueChange.emit(new Error(errorMessage));
  }

  private processImage(file: File) {
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      this.imageSrc$.next(e.target?.result as string);
      this.cdr.markForCheck();
    };
    reader.readAsDataURL(file);
  }

  private refreshData(): void {
    if (this.refresh) {
      const store = this.storeHelper.getStoreByName(this.refresh);

      if (store && 'refresh' in store) {
        store
          .refresh()
          .pipe(take(1))
          .subscribe(() => {
            this.setValue(null);
          });
      }
    }
  }

  private getUploadStateTitle(state: UploadState) {
    switch (state) {
      case UploadState.empty:
        return this.label ? this.label : $localize`:@@upload.title.empty:Upload file`;
      case UploadState.selected:
        return $localize`:@@upload.title.selected:Selected file`;
      case UploadState.uploading:
        return $localize`:@@upload.title.uploading:Uploading file...`;
      case UploadState.processing:
        return $localize`:@@upload.title.processing:Processing file...`;
      case UploadState.uploaded:
        return $localize`:@@upload.title.uploaded:Upload completed`;
      case UploadState.deleting:
        return $localize`:@@upload.title.deleting:Deleting file...`;
      case UploadState.error:
        return $localize`:@@upload.title.error:Upload failed`;
    }
  }
}
