<semmie-card>
  <div class="flex items-stretch">
    <div class="grow">
      <semmie-label *ngIf="referral.name" class="referral-card-title" type="heading" size="h3" [text]="referral.name"></semmie-label>
      <semmie-label
        *ngIf="referral.state !== ReferralState.FINISHED; else accountName"
        class="referral-card-subtitle"
        size="base_3"
        [text]="'boosters.referrals.state.' + referral.state | translate"
      ></semmie-label>
      <ng-template #accountName>
        <semmie-label
          class="referral-card-subtitle"
          size="base_3"
          [text]="referral.account?.title ?? ('core.common.account.title_unknown' | translate)"
        ></semmie-label>
      </ng-template>
    </div>
    <div class="self-center" [ngSwitch]="referral.state">
      <semmie-progress-circle *ngSwitchCase="ReferralState.STARTED" [value]="25"></semmie-progress-circle>
      <semmie-progress-circle *ngSwitchCase="ReferralState.ACCOUNT" [value]="50"></semmie-progress-circle>
      <semmie-progress-circle *ngSwitchCase="ReferralState.PAYMENT" [value]="75"></semmie-progress-circle>
      <ng-container *ngSwitchDefault>
        <ng-container [ngSwitch]="referral.rewardKind">
          <semmie-label
            *ngSwitchCase="RewardKind.CASH"
            class="referral-card-reward"
            size="base_3"
            [text]="referral.price | semmieCurrency: true : '1.0-2'"
          ></semmie-label>
          <semmie-label
            *ngSwitchCase="RewardKind.MONTHS_NO_FEE"
            class="referral-card-reward"
            size="base_3"
            [text]="
              '+' +
              ('boosters.rewards.months_no_fee'
                | translate
                  : {
                      amount:
                        (referral.amount | number: '1.0') +
                        ' ' +
                        ((referral.amount | number: '1.0') === '1'
                          ? ('boosters.terms.month' | translate)
                          : ('boosters.terms.months' | translate)),
                    })
            "
          ></semmie-label>
        </ng-container>
      </ng-container>
    </div>
  </div>
</semmie-card>
